import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Contact from "../components/Section/Contact";
import Link from "../components/LocalizedLink";
import useLocale from "../contexts/locale";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    posts: {
      nodes: Array<{
        uid: string;
        data: {
          title: {
            text: string;
          };
          body: {
            text: string;
          };
          post_date: {
            text: string;
          };
          cover: any;
        };
      }>;
    };
    contact: {
      nodes: Array<any>;
    };
  };
  location: Location;
  pageContext: { locale: "mn-mn" | "en-us" };
}

const BlogList = ({ data }: any) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  return (
    <>
      <div className="pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="text-center">
                <h1>{i18nData.blog}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {data.posts.nodes.map((post: any) => (
                <article
                  key={post.uid}
                  className="postbox post format-image mb-40"
                >
                  <div className="postbox__thumb mb-30">
                    <Link to={`/blog/${post.uid}`}>
                      <Img
                        fluid={post.data.cover.localFile.childImageSharp.fluid}
                      />
                    </Link>
                  </div>
                  <div className="postbox__text p-50">
                    <div className="post-meta mb-15">
                      <span>
                        <i className="far fa-calendar-check"></i>{" "}
                        {post.data.post_date}
                      </span>
                    </div>
                    <h3 className="blog-title">
                      <Link to={`/blog/${post.uid}`}>
                        {post.data.title.text}
                      </Link>
                    </h3>
                    <div className="post-text mb-20">
                      <p>{post.data.body.text.substr(0, 200)}</p>
                    </div>
                    <div className="read-more mt-30">
                      <Link to={`/blog/${post.uid}`} className="btn">
                        <span className="btn-text">
                          Read More <i className="far fa-long-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BlogPage = (props: IProps) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout {...props} title={siteTitle}>
      <SEO title={`Blog`} />
      <BlogList data={data} />
      <Contact data={data.contact} />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogListQuery($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    posts: allPrismicBlogPost(
      sort: { fields: data___post_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      nodes {
        uid
        data {
          post_date(formatString: "YYYY/MM/DD")
          title {
            text
          }
          body {
            text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    contact: allPrismicForms(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          description {
            text
            html
          }
          backgroundimage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          buttontitle {
            text
          }
          successmessage {
            text
          }
          errormessage {
            text
          }
          body {
            slice_type
            items {
              label {
                text
              }
              field_name {
                text
              }
              type
              placeholder {
                text
              }
            }
          }
        }
      }
    }
  }
`;
